import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Home.css";

const Home = () => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [firstProductID, setFirstProductID] = useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchSuggestions = useCallback(async () => {
    if (!searchInput.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `https://admin.comparejunction.com/api/search?q=${searchInput}`
      );

      const results = response.data.results;

      const filteredResults = results.filter(
        (product) =>
          (!firstProductID || product.ProductID === firstProductID) &&
          (!productCategory || product.Category === productCategory) &&
          !selectedProducts.some(
            (selected) => selected.ModelName === product.ModelName
          )
      );

      setSuggestions(filteredResults);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  }, [searchInput, firstProductID, productCategory, selectedProducts]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput) fetchSuggestions();
    }, 500);
    return () => clearTimeout(timer);
  }, [searchInput, fetchSuggestions]);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handleSelectProduct = (product) => {
    if (!firstProductID) {
      setFirstProductID(product.ProductID);
      setProductCategory(product.Category);
    }
    setSelectedProducts((prev) => [...prev, product]);
    setSearchInput("");
    setSuggestions([]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);

    if (updatedProducts.length === 0) {
      setFirstProductID(null);
      setProductCategory(null);
    }
  };

  const handleCompareProducts = () => {
    if (selectedProducts.length < 2) {
      alert("Please select at least two products for comparison.");
      return;
    }

    const productIDs = selectedProducts.map((product) => product.ProductID);
    const uniqueProductIDs = [...new Set(productIDs)];

    if (uniqueProductIDs.length > 1) {
      alert("Please select products from the same category for comparison.");
      return;
    }

    const productCategoryPageMap = {
      1: "/comparesmartphone",
      2: "/comparetablet",
      3: "/comparesmartwatch",
      4: "/comparelaptop",
    };

    const path = productCategoryPageMap[uniqueProductIDs[0]] || "/compare";
    navigate(path, { state: { products: selectedProducts } });
  };

  return (
    <div className="home-container text-black min-h-screen relative">
      <div className="content flex flex-col items-center mt-10">
        <h1 className="title text-5xl font-extrabold text-center">
          Compare <span className="highlight text-orange-600">Junction</span>
        </h1>
        <p className="description text-lg text-center mt-3 max-w-md text-gray-500">
          Compare products from our wide selection!
        </p>

        {/* Integrated Search Bar */}
        <div className="search-container relative w-full flex justify-center items-center mt-6">
          <input
            type="text"
            placeholder="Search for products..."
            value={searchInput}
            onChange={handleSearchChange}
            className="search-input text-black px-5 py-3 rounded-lg w-3/4 md:w-1/2 focus:outline-none focus:ring-2 focus:ring-orange-600"
          />
          {loading && (
            <div className="loading absolute mt-2 p-2 bg-white text-gray-700 w-full">
              Loading...
            </div>
          )}
          {!loading && !suggestions.length && searchInput && (
            <div className="no-results absolute mt-2 p-2 bg-white text-gray-700 w-full">
              No products found
            </div>
          )}
          {!loading && suggestions.length > 0 && (
            <ul className="suggestions absolute mt-2 rounded-md w-full z-10 max-h-64 overflow-y-auto shadow-lg border bg-white">
              {suggestions.map((product) => (
                <li
                  key={product.ProductID}
                  className="suggestion-item px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center"
                  onClick={() => handleSelectProduct(product)}
                >
                  <img
                    src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                    alt={product.ModelName}
                    className="product-image w-12"
                    onError={(e) => {
                      e.target.src = "/fallback_image.png";
                    }}
                  />
                  <div className="product-info ml-4">
                    <p className="product-name font-bold">{product.ModelName}</p>
                    <p className="product-brand text-gray-500">
                      {product.BrandName}
                    </p>
                    <p className="product-price text-green-600 font-semibold">
                      {product.Price ? `₹${product.Price}` : "Price not available"}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="selected-products flex items-center gap-6 justify-center mt-8">
        {selectedProducts.map((product, index) => (
          <React.Fragment key={index}>
            <div className="selected-product flex items-center p-4 border rounded shadow-md">
              <img
                src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                alt={product.ModelName}
                className="product-image w-12"
                onError={(e) => {
                  e.target.src = "/fallback_image.png";
                }}
              />
              <div className="product-details ml-4">
                <p className="product-name font-bold">{product.ModelName}</p>
                <p className="product-brand text-gray-500">
                  {product.BrandName}
                </p>
              </div>
              <button
                onClick={() => handleRemoveProduct(index)}
                className="remove-button ml-auto text-red-600 font-bold"
              >
                Remove
              </button>
            </div>
            {index < selectedProducts.length - 1 && (
              <span className="vs-indicator text-orange-600 text-2xl font-bold">
                VS
              </span>
            )}
          </React.Fragment>
        ))}
      </div>

      {selectedProducts.length > 0 && (
        <button
          onClick={handleCompareProducts}
          className="compare-button mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700"
        >
          Compare Products
        </button>
      )}
    </div>
  );
};

export default Home;

import React from 'react';
import './styles/Footer.css';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>© 2024 CompareJunction. All rights reserved.</p>
        <nav>
          <a href="/privacy">Privacy Policy</a> <a href="/terms">Terms of Use</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TabletCompare.css'; // Scoped CSS for TabletCompare

const fallbackImage = 'path_to_fallback_image.png'; // Define a fallback image path

const TabletCompare = () => {
  const location = useLocation();
  const { tablets } = location.state || {}; // Extract selected tablets from location state

  const navigate = useNavigate(); // Navigation handler

  const [showOnlyDifferences, setShowOnlyDifferences] = useState(false);

  // If no tablets are selected for comparison
  if (!tablets || tablets.length < 2) {
    return (
      <div className="tablet-compare-wrapper">
        <p>No tablets selected for comparison.</p>
        <button onClick={() => navigate('/')}>Go back to Home</button> {/* Navigate to homepage */}
      </div>
    );
  }

  // Function to check if all tablets have the same value for a particular feature
  const areValuesSame = (featureKey) => {
    const firstValue = formatValue(tablets[0][featureKey]);
    return tablets.every(tablet => formatValue(tablet[featureKey]) === firstValue);
  };

  // Helper function to format values: Convert 1 to "YES" and 0 to "NO"
  const formatValue = (value) => {
    if (value === 1) return 'YES';
    if (value === 0) return 'NO';
    return value !== null && value !== undefined ? value : 'N/A';
  };

  // List of feature keys and labels to display in the table
  const featureList = [
    { key: 'Price', label: 'Price' },
    { key: 'Announced', label: 'Announced' },
    { key: 'Status', label: 'Status' },
    { key: 'NetworkTechnology', label: 'Network Technology' },
    { key: 'WLAN', label: 'WLAN' },
    { key: 'Bluetooth', label: 'Bluetooth' },
    { key: 'Dimensions', label: 'Dimensions' },
    { key: 'Weight', label: 'Weight (g)' },
    { key: 'Build', label: 'Build' },
    { key: 'Sim', label: 'SIM' },
    { key: 'WaterResistance', label: 'Water Resistance' },
    { key: 'DisplayType', label: 'Display Type' },
    { key: 'DisplaySize', label: 'Display Size' },
    { key: 'DisplayResolution', label: 'Display Resolution' },
    { key: 'OS', label: 'Operating System' },
    { key: 'Chipset', label: 'Chipset' },
    { key: 'CPU', label: 'CPU' },
    { key: 'GPU', label: 'GPU' },
    { key: 'RAM', label: 'RAM' },
    { key: 'InternalMemory', label: 'Internal Storage' },
    { key: 'MainCamera', label: 'Main Camera' },
    { key: 'FrontCamera', label: 'Front Camera' },
    { key: 'BatteryCapacity', label: 'Battery Capacity' },
    { key: 'ChargingSpeed', label: 'Charging Speed' },
    { key: 'USB', label: 'USB' },
    { key: 'Sensors', label: 'Sensors' },
    { key: 'NFC', label: 'NFC' },
    { key: 'SoundLoudspeaker', label: 'Loudspeaker' },
    { key: 'Colors', label: 'Available Colors' },
    { key: 'BoxContents', label: 'Box Contents' }
  ];

  return (
    <div className="tablet-compare-container">
      <h1>Tablet Comparison</h1>

      {/* Button to go back to the homepage */}
      <button onClick={() => navigate('/')}>Go back to Home</button>

      {/* Toggle to show only differences */}
      <div className="toggle-differences">
        <label>
          <input
            type="checkbox"
            checked={showOnlyDifferences}
            onChange={() => setShowOnlyDifferences(!showOnlyDifferences)}
          />
          Show only differences
        </label>
      </div>

      <div className="comparison-table-wrapper">
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Feature</th>
              {tablets.map(tablet => (
                <th key={tablet.TabletID}>
                  <img
                    src={`https://admin.comparejunction.com/storage/images/${tablet.ImageURL}`}
                    alt={tablet.ModelName}
                    className="tablet-image"
                    onError={(e) => { e.target.src = fallbackImage; }} // Handle broken images
                  />
                  <br />
                  {tablet.brand.BrandName} {tablet.ModelName} ({tablet.TabletID})
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {featureList.map(({ key, label }) => {
              // If "Show only differences" is enabled, skip rows where all values are the same
              if (showOnlyDifferences && areValuesSame(key)) {
                return null;
              }

              return (
                <tr key={key}>
                  <td>{label}</td>
                  {tablets.map(tablet => (
                    <td key={tablet.TabletID}>{formatValue(tablet[key])}</td>
                  ))}
                </tr>
              );
            })}
            {/* Display message if no differences are found when filter is active */}
            {!tablets.some(tablet => featureList.some(({ key }) => !areValuesSame(key))) && showOnlyDifferences ? (
              <tr>
                <td colSpan={tablets.length + 1}>No differences found</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabletCompare;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";  // Ensure you import Link from 'react-router-dom'

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch categories
    fetch("https://admin.comparejunction.com/api/categories")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));

    // Fetch products
    fetch("https://admin.comparejunction.com/api/products")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  // Group products by category
  const getProductsByCategory = (categoryId) =>
    products.filter((product) => product.CategoryID === categoryId);

  return (
    <div style={{ maxWidth: "1200px", margin: "20px auto", padding: "20px" }}>
      <h1 style={{ fontSize: "28px", fontWeight: "bold", color: "#333", marginBottom: "30px" }}>
        Categories
      </h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "20px",
        }}
      >
        {categories.map((category) => (
          <div
            key={category.CategoryID}
            style={{
              border: "1px solid #ccc",
              borderRadius: "10px",
              backgroundColor: "#f9f9f9",
              padding: "20px",
              textAlign: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s",
            }}
            onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "10px",
                color: "#333",
              }}
            >
              {category.CategoryName}
            </h3>
            <p style={{ fontSize: "14px", color: "#555", marginBottom: "15px" }}>
              {category.Description}
            </p>
            <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
              {getProductsByCategory(category.CategoryID).map((product) => (
                <li
                  key={product.ProductID}
                  style={{
                    fontSize: "14px",
                    color: "#007BFF",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                >
                  {/* Link to the product page dynamically using the 'Url' field */}
                  <Link
                    to={`https://test.comparejunction.com/${product.Url}`}  // Updated URL to use the 'Url' field
                    style={{
                      textDecoration: "none",
                      color: "#007BFF",
                    }}
                    onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  >
                    {product.ProductName}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryPage;

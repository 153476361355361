import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SmartphoneCompare.css'; // Scoped CSS for SmartphoneCompare

const fallbackImage = 'path_to_fallback_image.png'; // Define a fallback image path

const SmartphoneCompare = () => {
  const location = useLocation();
  const { phones } = location.state || {};

  const navigate = useNavigate();
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(false);

  // If no phones are selected for comparison, show a message
  if (!phones || phones.length < 2) {
    return (
      <div className="smartphone-compare-wrapper">
        <p>No phones selected for comparison.</p>
        <button onClick={() => navigate('/')}>Go back to Home</button>
      </div>
    );
  }

  // Helper function to check if values for a feature are the same across all phones
  const areValuesSame = (featureKey) => {
    const firstValue = formatValue(phones[0][featureKey]);
    return phones.every(phone => formatValue(phone[featureKey]) === firstValue);
  };

  // Helper function to format the values for display (YES/NO for boolean, or N/A for null/undefined)
  const formatValue = (value) => {
    if (value === 1) return 'YES';
    if (value === 0) return 'NO';
    return value !== null && value !== undefined ? value : 'N/A';
  };

  // List of features to compare
  const featureList = [
    { key: 'Price', label: 'Price' },
    { key: 'Announced', label: 'Announced' },
    { key: 'Status', label: 'Status' },
    { key: 'NetworkTechnology', label: 'Network Technology' },
    { key: 'Band2G', label: '2G Bands' },
    { key: 'Band3G', label: '3G Bands' },
    { key: 'Band4G', label: '4G Bands' },
    { key: 'Band5G', label: '5G Bands' },
    { key: 'NetworkSpeed', label: 'Network Speed' },
    { key: 'WiFiVersion', label: 'WiFi Version' },
    { key: 'BluetoothVersion', label: 'Bluetooth Version' },
    { key: 'NFC', label: 'NFC' },
    { key: 'WLAN', label: 'WLAN' },
    { key: 'InfraredPort', label: 'Infrared Port' },
    { key: 'Radio', label: 'Radio' },
    { key: 'USBType', label: 'USB Type' },
    { key: 'Dimensions', label: 'Dimensions' },
    { key: 'Weight', label: 'Weight (g)' },
    { key: 'Build', label: 'Build' },
    { key: 'IPRating', label: 'IP Rating' },
    { key: 'WaterResistance', label: 'Water Resistance' },
    { key: 'DisplayType', label: 'Display Type' },
    { key: 'ScreenSize', label: 'Screen Size' },
    { key: 'Resolution', label: 'Resolution' },
    { key: 'RefreshRate', label: 'Refresh Rate' },
    { key: 'DisplayFeatures', label: 'Display Features' },
    { key: 'Protection', label: 'Protection' },
    { key: 'Processor', label: 'Processor' },
    { key: 'GPU', label: 'GPU' },
    { key: 'Chipset', label: 'Chipset' },
    { key: 'OperatingSystem', label: 'Operating System' },
    { key: 'CustomUI', label: 'Custom UI' },
    { key: 'RAM', label: 'RAM' },
    { key: 'InternalStorage', label: 'Internal Storage' },
    { key: 'ExpandableStorage', label: 'Expandable Storage' },
    { key: 'MemoryCardSlot', label: 'Memory Card Slot' },
    { key: 'RearCameraSetup', label: 'Rear Camera Setup' },
    { key: 'MainCamera', label: 'Main Camera' },
    { key: 'MainCameraVideo', label: 'Main Camera Video' },
    { key: 'CameraFeatures', label: 'Camera Features' },
    { key: 'FrontCamera', label: 'Front Camera' },
    { key: 'SelfieCameraType', label: 'Selfie Camera Type' },
    { key: 'SelfieCameraVideo', label: 'Selfie Camera Video' },
    { key: 'SelfieCameraFeatures', label: 'Selfie Camera Features' },
    { key: 'BatteryCapacity', label: 'Battery Capacity' },
    { key: 'ChargingSpeed', label: 'Charging Speed' },
    { key: 'WirelessCharging', label: 'Wireless Charging' },
    { key: 'BatteryType', label: 'Battery Type' },
    { key: 'BatteryCharging', label: 'Battery Charging' },
    { key: 'BatteryWirelessCharging', label: 'Wireless Charging' },
    { key: 'BatteryReverseCharging', label: 'Reverse Charging' },
    { key: 'SoundLoudspeaker', label: 'Loudspeaker' },
    { key: 'Sound3_5mmJack', label: '3.5mm Jack' },
    { key: 'Positioning', label: 'Positioning' },
    { key: 'Sensors', label: 'Sensors' },
    { key: 'SpecialFeatures', label: 'Special Features' },
    { key: 'Colors', label: 'Available Colors' },
    { key: 'Models', label: 'Models' },
    { key: 'SAR', label: 'SAR Value' },
    { key: 'BoxContents', label: 'Box Contents' }
  ];

  return (
    <div className="smartphone-compare-container">
      <h1>Comparison</h1>
      <button onClick={() => navigate('/')}>Go back to Home</button>

      <div className="toggle-differences">
        <label>
          <input
            type="checkbox"
            checked={showOnlyDifferences}
            onChange={() => setShowOnlyDifferences(!showOnlyDifferences)}
          />
          Show only differences
        </label>
      </div>

      <div className="comparison-table-wrapper">
        <div className="comparison-scroll-container">
          <table className="comparison-table">
            <thead>
              <tr>
                <th>Feature</th>
                {phones.map(phone => (
                  <th key={phone.SmartphoneID} className="phone-info">
                    <img
                      src={`https://admin.comparejunction.com/storage/images/${phone.ImageURL}`}
                      alt={phone.ModelName}
                      className="phone-image"
                      onError={(e) => { e.target.src = fallbackImage; }}
                    />
                    <br />
                    <span className="phone-name">{phone.brand.BrandName} {phone.ModelName}</span>
                    <br />
                    <span className="phone-price">₹{phone.Price}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {featureList.map(({ key, label }) => {
                if (showOnlyDifferences && areValuesSame(key)) return null;

                return (
                  <tr key={key}>
                    <td>{label}</td>
                    {phones.map(phone => (
                      <td key={phone.SmartphoneID}>{formatValue(phone[key])}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SmartphoneCompare;

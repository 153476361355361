import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // Mobile menu toggle
  const [categories, setCategories] = useState([]); // Categories from API
  const [categoryProducts, setCategoryProducts] = useState({}); // Products for each category
  const [activeCategory, setActiveCategory] = useState(null); // Track which category is active

  // Toggle mobile menu
  const toggleMenu = () => setIsOpen(!isOpen);

  // Fetch categories on mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get('https://admin.comparejunction.com/api/categories');
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Fetch products for a specific category
  const fetchProductsForCategory = async (categoryId) => {
    try {
      const { data } = await axios.get(`https://admin.comparejunction.com/api/products?categoryId=${categoryId}`);
      setCategoryProducts((prev) => ({
        ...prev,
        [categoryId]: data,
      }));
      setActiveCategory(categoryId); // Set the active category
    } catch (error) {
      console.error('Error fetching products for category:', error);
    }
  };

  // Close mobile menu when a link is clicked
  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu
  };

  return (
    <header className="bg-gray-900 text-white shadow-lg">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="text-2xl font-bold tracking-wide" onClick={handleLinkClick}>
          Compare<span className="text-blue-500">Junction</span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-8">
          <div className="relative group">
            <button className="flex items-center space-x-2 hover:text-blue-500 focus:outline-none">
              {/* <span>CATEGORIES</span> */}
              <Link to="/categories" className="hover:text-blue-500" onClick={handleLinkClick}>CATEGORIES</Link>
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M5.23 7.77a.75.75 0 011.06-1.06l3.25 3.25 3.25-3.25a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0L5.23 7.77z" clipRule="evenodd" />
              </svg>
            </button>
            <div className="absolute hidden group-hover:block bg-white text-black mt-2 rounded shadow-lg p-4">
              <ul className="space-y-2">
                {categories.map((category) => (
                  <li key={category.id}>
                    <button
                      className="block hover:text-blue-500"
                      onClick={() => fetchProductsForCategory(category.id)} // Fetch products only on click
                    >
                      {category.name}
                    </button>

                    {/* Display Products for Each Category */}
                    {activeCategory === category.id && categoryProducts[category.id] && categoryProducts[category.id].length > 0 && (
                      <ul className="mt-2 space-y-1">
                        {categoryProducts[category.id].map((product) => (
                          <li key={product.id}>
                            <Link to={`/products/${product.id}`} className="block text-gray-700 hover:text-blue-500" onClick={handleLinkClick}>
                              {product.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Link to="/smartphones" className="hover:text-blue-500" onClick={handleLinkClick}>SMARTPHONES</Link>
          <Link to="/tablets" className="hover:text-blue-500" onClick={handleLinkClick}>TABLETS</Link>
          <Link to="/smartwatches" className="hover:text-blue-500" onClick={handleLinkClick}>SMART WATCHES</Link>
          <Link to="/laptops" className="hover:text-blue-500" onClick={handleLinkClick}>LAPTOPS</Link>
        </nav>

        {/* Mobile Menu Button */}
        <button onClick={toggleMenu} className="md:hidden text-white focus:outline-none">
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M4.5 5.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-1zM4.5 9.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-1zM4.5 13.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-1z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-900 text-white">
          <nav className="space-y-4 px-4 py-6">
            <Link to="/categories" className="block hover:text-blue-500" onClick={handleLinkClick}>CATEGORIES</Link>
            <Link to="/smartphones" className="block hover:text-blue-500" onClick={handleLinkClick}>SMARTPHONES</Link>
            <Link to="/tablets" className="block hover:text-blue-500" onClick={handleLinkClick}>TABLETS</Link>
            <Link to="/smartwatches" className="block hover:text-blue-500" onClick={handleLinkClick}>SMART WATCHES</Link>
            <Link to="/laptops" className="block hover:text-blue-500" onClick={handleLinkClick}>LAPTOPS</Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;

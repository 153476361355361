import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Pages
import Home from './pages/Home';
import CompareSmartphonePage from './pages/CompareSmartphonePage';
import CompareSmartphones from './pages/CompareSmartphones';
import SmartphoneDetails from './pages/SmartphoneDetails';
import SmartphoneCompare from './pages/SmartphoneCompare';
import CompareTabletPage from './pages/CompareTabletPage';
import CompareTablets from './pages/CompareTablets';
import TabletCompare from './pages/TabletCompare';
import TabletDetails from './pages/TabletDetails';
import CategoryPage from  './pages/CategoryPage';

// Components
import Header from './components/Header'; 
import HeaderPopup from './components/HeaderPopup';
import Footer from './components/Footer'; 

import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/smartphones" element={<CompareSmartphones />} />
            <Route path="/smartphonecompare" element={<SmartphoneCompare />} />
            <Route path="/smartphonedetails/:id" element={<SmartphoneDetails />} />
            <Route path="/comparesmartphone" element={<CompareSmartphonePage />} />
            <Route path="/comparetablet" element={<CompareTabletPage />} />
            <Route path="/tablets" element={<CompareTablets />} />
            <Route path="/tabletcompare" element={<TabletCompare />} />
            <Route path="/tabletdetails/:id" element={<TabletDetails />} />
            <Route path="/headerpopup" element={<HeaderPopup />} />
            <Route path="/categories" element={<CategoryPage />} />
            
            {/* Catch-all for 404 */}
            <Route path="*" element={<div>404 - Page Not Found</div>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

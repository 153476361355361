import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './TabletDetails.css'; // Import CSS for styling

const TabletDetails = () => {
  const { id } = useParams();  // Get the tablet ID from the URL
  const [tablet, setTablet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTabletDetails = async () => {
      try {
        const response = await axios.get(`https://admin.comparejunction.com/api/tablets/${id}`);
        setTablet(response.data.data);
      } catch (err) {
        setError('Failed to fetch tablet details');
      } finally {
        setLoading(false);
      }
    };

    fetchTabletDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    tablet ? (
      <div className="tablet-details">
        <div className="tablet-image-section">
          <img 
            src={`https://admin.comparejunction.com/storage/images/${tablet.ImageURL}`} 
            alt={tablet.ModelName} 
            className="tablet-image"
          />
        </div>
        <div className="tablet-specs-section">
          <h2>{tablet.ModelName}</h2>
          <p><strong>Brand:</strong> {tablet.brand.BrandName}</p>
          <p><strong>Category:</strong> {tablet.category.CategoryName}</p>
          <p><strong>Price:</strong> {tablet.Price} {tablet.Currency}</p>
          <p><strong>Announced:</strong> {tablet.Announced}</p>
          <p><strong>Status:</strong> {tablet.Status}</p>
          <h3>Specifications</h3>
          <div className="specs-container">
            <div className="spec-item"><strong>Network Technology:</strong> {tablet.NetworkTechnology}</div>
            <div className="spec-item"><strong>2G Bands:</strong> {tablet['2GBands'] || 'N/A'}</div>
            <div className="spec-item"><strong>3G Bands:</strong> {tablet['3GBands'] || 'N/A'}</div>
            <div className="spec-item"><strong>4G Bands:</strong> {tablet['4GBands'] || 'N/A'}</div>
            <div className="spec-item"><strong>5G Bands:</strong> {tablet['5GBands'] || 'N/A'}</div>
            <div className="spec-item"><strong>Network Speed:</strong> {tablet.NetworkSpeed || 'N/A'}</div>
            <div className="spec-item"><strong>WLAN:</strong> {tablet.WLAN}</div>
            <div className="spec-item"><strong>Bluetooth Version:</strong> {tablet.Bluetooth}</div>
            <div className="spec-item"><strong>Dimensions:</strong> {tablet.Dimensions}</div>
            <div className="spec-item"><strong>Weight:</strong> {tablet.Weight}</div>
            <div className="spec-item"><strong>Build:</strong> {tablet.Build}</div>
            <div className="spec-item"><strong>Sim:</strong> {tablet.Sim}</div>
            <div className="spec-item"><strong>Water Resistance:</strong> {tablet.WaterResistance}</div>
            <div className="spec-item"><strong>Display Type:</strong> {tablet.DisplayType}</div>
            <div className="spec-item"><strong>Screen Size:</strong> {tablet.DisplaySize}</div>
            <div className="spec-item"><strong>Display Resolution:</strong> {tablet.DisplayResolution}</div>
            <div className="spec-item"><strong>Operating System:</strong> {tablet.OS}</div>
            <div className="spec-item"><strong>Chipset:</strong> {tablet.Chipset}</div>
            <div className="spec-item"><strong>CPU:</strong> {tablet.CPU}</div>
            <div className="spec-item"><strong>GPU:</strong> {tablet.GPU}</div>
            <div className="spec-item"><strong>Memory Card Slot:</strong> {tablet.MemoryCardSlot || 'N/A'}</div>
            <div className="spec-item"><strong>Internal Memory:</strong> {tablet.InternalMemory}</div>
            <div className="spec-item"><strong>Main Camera:</strong> {tablet.MainCamera}</div>
            <div className="spec-item"><strong>Main Camera Video:</strong> {tablet.MainCameraVideo}</div>
            <div className="spec-item"><strong>Main Camera Features:</strong> {tablet.MainCameraFeatures || 'N/A'}</div>
            <div className="spec-item"><strong>Selfie Camera:</strong> {tablet.SelfieCamera}</div>
            <div className="spec-item"><strong>Selfie Camera Video:</strong> {tablet.SelfieCameraVideo}</div>
            <div className="spec-item"><strong>Selfie Camera Features:</strong> {tablet.SelfieCameraFeatures || 'N/A'}</div>
            <div className="spec-item"><strong>Sound Loudspeaker:</strong> {tablet.SoundLoudspeaker}</div>
            <div className="spec-item"><strong>3.5mm Jack:</strong> {tablet.Sound3_5mmJack}</div>
            <div className="spec-item"><strong>Positioning:</strong> {tablet.Positioning || 'N/A'}</div>
            <div className="spec-item"><strong>NFC:</strong> {tablet.NFC}</div>
            <div className="spec-item"><strong>Infrared Port:</strong> {tablet.InfraredPort || 'N/A'}</div>
            <div className="spec-item"><strong>Radio:</strong> {tablet.Radio}</div>
            <div className="spec-item"><strong>USB Type:</strong> {tablet.USB}</div>
            <div className="spec-item"><strong>Sensors:</strong> {tablet.Sensors}</div>
            <div className="spec-item"><strong>Battery Type:</strong> {tablet.BatteryType}</div>
            <div className="spec-item"><strong>Battery Charging:</strong> {tablet.BatteryCharging}</div>
            <div className="spec-item"><strong>Battery Wireless Charging:</strong> {tablet.BatteryWirelessCharging || 'N/A'}</div>
            <div className="spec-item"><strong>Battery Reverse Charging:</strong> {tablet.BatteryReverseCharging || 'N/A'}</div>
            <div className="spec-item"><strong>Colors:</strong> {tablet.Colors}</div>
            <div className="spec-item"><strong>Models:</strong> {tablet.Models || 'N/A'}</div>
            <div className="spec-item"><strong>SAR:</strong> {tablet.SAR || 'N/A'}</div>
            <div className="spec-item"><strong>Box Contents:</strong> {tablet.BoxContents || 'N/A'}</div>
          </div>
        </div>
      </div>
    ) : (
      <div>No details available for this tablet.</div>
    )
  );
};

export default TabletDetails;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HeaderPopup = () => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [firstProductID, setFirstProductID] = useState(null);
  const [productCategory, setProductCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  const fetchSuggestions = useCallback(async () => {
    if (!searchInput.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `https://admin.comparejunction.com/api/search?q=${searchInput}`
      );
      const results = response.data.results;

      const filteredResults = results.filter(
        (product) =>
          (!firstProductID || product.ProductID === firstProductID) &&
          (!productCategory || product.Category === productCategory) &&
          !selectedProducts.some(
            (selected) => selected.ModelName === product.ModelName
          )
      );

      setSuggestions(filteredResults);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  }, [searchInput, firstProductID, productCategory, selectedProducts]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput) fetchSuggestions();
    }, 500);
    return () => clearTimeout(timer);
  }, [searchInput, fetchSuggestions]);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handleSelectProduct = (product) => {
    if (!firstProductID) {
      setFirstProductID(product.ProductID);
      setProductCategory(product.Category);
    }
    setSelectedProducts((prev) => [...prev, product]);
    setSearchInput("");
    setSuggestions([]);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = selectedProducts.filter((_, i) => i !== index);
    setSelectedProducts(updatedProducts);

    if (updatedProducts.length === 0) {
      setFirstProductID(null);
      setProductCategory(null);
    }
  };

  const handleCompareProducts = () => {
    if (selectedProducts.length < 2) {
      alert("Please select at least two products for comparison.");
      return;
    }

    const productIDs = selectedProducts.map((product) => product.ProductID);
    const uniqueProductIDs = [...new Set(productIDs)];

    if (uniqueProductIDs.length > 1) {
      alert("Please select products from the same category for comparison.");
      return;
    }

    const productCategoryPageMap = {
      1: "/comparesmartphone",
      2: "/comparetablet",
      3: "/comparesmartwatch",
      4: "/comparelaptop",
    };

    const path = productCategoryPageMap[uniqueProductIDs[0]] || "/compare";
    navigate(path, { state: { products: selectedProducts } });
  };

  return (
    <div>
      <button
        onClick={() => setIsPopupOpen(!isPopupOpen)}
        className="popup-toggle-btn bg-blue-600 text-white px-4 py-2 rounded"
      >
        Search & Compare
      </button>

      {isPopupOpen && (
        <div className="popup-container fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="popup-content bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
            <button
              onClick={() => setIsPopupOpen(false)}
              className="close-btn text-red-600 text-xl absolute top-4 right-4"
            >
              ×
            </button>

            <h2 className="text-xl font-bold mb-4">Search Products</h2>

            <input
              type="text"
              placeholder="Search for products..."
              value={searchInput}
              onChange={handleSearchChange}
              className="search-input w-full p-3 border rounded mb-4"
            />
            {loading && <div className="text-gray-500">Loading...</div>}
            {!loading && !suggestions.length && searchInput && (
              <div className="text-gray-500">No products found</div>
            )}
            {!loading && suggestions.length > 0 && (
              <ul className="suggestions-list max-h-40 overflow-y-auto border rounded mb-4">
                {suggestions.map((product) => (
                  <li
                    key={product.ProductID}
                    className="suggestion-item p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                    onClick={() => handleSelectProduct(product)}
                  >
                    <img
                      src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                      alt={product.ModelName}
                      className="w-10 h-10"
                    />
                    <div className="ml-4">
                      <p className="font-bold">{product.ModelName}</p>
                      <p className="text-gray-500">{product.BrandName}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}

            <h3 className="text-lg font-semibold mb-2">Selected Products</h3>
            {selectedProducts.map((product, index) => (
              <div
                key={index}
                className="selected-product flex items-center border p-2 mb-2 rounded"
              >
                <img
                  src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                  alt={product.ModelName}
                  className="w-10 h-10"
                />
                <div className="ml-4">
                  <p className="font-bold">{product.ModelName}</p>
                  <p className="text-gray-500">{product.BrandName}</p>
                </div>
                <button
                  onClick={() => handleRemoveProduct(index)}
                  className="ml-auto text-red-600 font-bold"
                >
                  Remove
                </button>
              </div>
            ))}

            {selectedProducts.length > 0 && (
              <button
                onClick={handleCompareProducts}
                className="compare-btn bg-blue-600 text-white px-4 py-2 rounded w-full mt-4"
              >
                Compare
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderPopup;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CompareSmartphones.css'; // Ensure you import the CSS file

const CompareSmartphones = () => {
  const [smartphones, setSmartphones] = useState([]);
  const [selectedPhones, setSelectedPhones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://admin.comparejunction.com/api/smartphones')
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data && Array.isArray(response.data.data)) {
          setSmartphones(response.data.data);
        } else {
          setError('Unexpected API response format');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  const handleSelectPhone = (phone) => {
    if (selectedPhones.length < 5 && !selectedPhones.includes(phone)) {
      setSelectedPhones([...selectedPhones, phone]);
    } else if (selectedPhones.includes(phone)) {
      setSelectedPhones(selectedPhones.filter(p => p !== phone));
    }
  };

  const handleCompare = () => {
    if (selectedPhones.length >= 2 && selectedPhones.length <= 5) {
      navigate('/smartphonecompare', { state: { phones: selectedPhones } });
    } else {
      alert('Please select at least 2 phones and up to 5 phones to compare.');
    }
  };

  const handleViewDetails = (phone) => {
    navigate(`/smartphonedetails/${phone.SmartphoneID}`);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPhones = smartphones.filter(phone => 
    phone.ModelName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1>Compare Smartphones</h1>

      {/* Search Bar */}
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search for a smartphone..." 
          className="search-input"
          value={searchTerm}
          onChange={handleSearch} 
        />
        <button className="search-button">Search</button>
      </div>

      <div className="phone-list">
        {filteredPhones.length === 0 ? (
          <div>No data available</div>
        ) : (
          <div className="phone-cards">
            {filteredPhones.map((phone) => (
              <div
                key={phone.SmartphoneID}
                className={`phone-card ${selectedPhones.includes(phone) ? 'selected' : ''}`}
              >
                {/* Phone Image */}
                <img 
                  src={`https://admin.comparejunction.com/storage/images/${phone.ImageURL}`} 
                  alt={phone.ModelName} 
                  className="phone-image"
                />

                {/* Phone Information */}
                <div className="phone-info">
                  <h2 onClick={() => handleViewDetails(phone)} className="phone-title">
                    {phone.ModelName}
                  </h2>
                  {/* Price displayed in green */}
                  <p className="phone-price">₹{phone.Price}</p>
                  <div className="phone-specs">
                    <span><i className="fas fa-mobile-alt"></i> {phone.ScreenSize}"</span>
                    <span><i className="fas fa-memory"></i> {phone.RAM}GB</span>
                    <span><i className="fas fa-hard-drive"></i> {phone.InternalStorage}GB</span>
                    <span><i className="fas fa-battery-full"></i> {phone.BatteryCapacity} mAh</span>
                  </div>
                </div>

                {/* Add Button */}
                <button 
                  className="add-button"
                  onClick={() => handleSelectPhone(phone)}
                  disabled={selectedPhones.includes(phone)}
                >
                  {selectedPhones.includes(phone) ? '-' : '+'}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <button 
        className="compare-button"
        onClick={handleCompare}
        disabled={selectedPhones.length < 2}
      >
        Compare
      </button>
    </div>
  );
};

export default CompareSmartphones;

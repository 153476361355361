import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CompareTablets.css'; // Ensure you import the appropriate CSS file for tablets

const CompareTablets = () => {
  const [tablets, setTablets] = useState([]);
  const [selectedTablets, setSelectedTablets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Fetch tablet data on component mount
  useEffect(() => {
    axios.get('https://admin.comparejunction.com/api/tablets') // API endpoint for tablets
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data && Array.isArray(response.data.data)) {
          setTablets(response.data.data);
        } else {
          setError('Unexpected API response format');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  // Handle tablet selection
  const handleSelectTablet = (tablet) => {
    if (selectedTablets.length < 5 && !selectedTablets.includes(tablet)) {
      setSelectedTablets([...selectedTablets, tablet]);
    } else if (selectedTablets.includes(tablet)) {
      setSelectedTablets(selectedTablets.filter(t => t !== tablet));
    }
  };

  // Handle comparison of selected tablets
  const handleCompare = () => {
    if (selectedTablets.length >= 2 && selectedTablets.length <= 5) {
      navigate('/tabletcompare', { state: { tablets: selectedTablets } }); // Pass selected tablets to compare
    } else {
      alert('Please select at least 2 tablets and up to 5 tablets to compare.');
    }
  };

  // View details for a specific tablet
  const handleViewDetails = (tablet) => {
    navigate(`/tabletdetails/${tablet.TabletID}`); // Navigate to tablet details page
  };

  // Search handler for tablets
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter tablets based on the search term
  const filteredTablets = tablets.filter(tablet => 
    tablet.ModelName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Loading and error states
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1>Compare Tablets</h1>

      {/* Search Bar */}
      <div className="search-bar">
        <input 
          type="text" 
          placeholder="Search for a tablet..." 
          className="search-input"
          value={searchTerm}
          onChange={handleSearch} 
        />
        <button className="search-button">Search</button>
      </div>

      <div className="tablet-list">
        {filteredTablets.length === 0 ? (
          <div>No data available</div>
        ) : (
          <div className="tablet-cards">
            {filteredTablets.map((tablet) => (
              <div
                key={tablet.TabletID}
                className={`tablet-card ${selectedTablets.includes(tablet) ? 'selected' : ''}`}
              >
                {/* Tablet Image */}
                <img 
                  src={`https://admin.comparejunction.com/storage/images/${tablet.ImageURL}`} 
                  alt={tablet.ModelName} 
                  className="tablet-image"
                />

                {/* Tablet Information */}
                <div className="tablet-info">
                  <h2 onClick={() => handleViewDetails(tablet)} className="tablet-title">
                    {tablet.ModelName}
                  </h2>
                  {/* Price displayed in green */}
                  <p className="tablet-price">₹{tablet.Price}</p>
                  <div className="tablet-specs">
                    <span><i className="fas fa-mobile-alt"></i> {tablet.DisplaySize}"</span>
                    <span><i className="fas fa-memory"></i> {tablet.RAM}GB</span>
                    <span><i className="fas fa-hard-drive"></i> {tablet.InternalStorage}GB</span>
                    <span><i className="fas fa-battery-full"></i> {tablet.BatteryCapacity} mAh</span>
                  </div>
                </div>

                {/* Add Button */}
                <button 
                  className="add-button"
                  onClick={() => handleSelectTablet(tablet)}
                  disabled={selectedTablets.includes(tablet)}
                >
                  {selectedTablets.includes(tablet) ? '-' : '+'}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <button 
        className="compare-button"
        onClick={handleCompare}
        disabled={selectedTablets.length < 2}
      >
        Compare
      </button>
    </div>
  );
};

export default CompareTablets;

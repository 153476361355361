import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './CompareTabletPage.css'; // Scoped CSS for tablet comparison styling

const CompareTabletPage = () => {
  const location = useLocation();

  const [products, setProducts] = useState(location.state?.products || []);
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  useEffect(() => {
    const savedProducts = JSON.parse(localStorage.getItem('selectedProducts'));
    if (savedProducts) {
      setProducts(savedProducts);
    }
  }, []);

  useEffect(() => {
    if (products.length) {
      localStorage.setItem('selectedProducts', JSON.stringify(products));
    }
  }, [products]);

  useEffect(() => {
    const fetchAvailableProducts = async () => {
      try {
        const response = await axios.get('https://admin.comparejunction.com/api/tablets');
        if (Array.isArray(response.data.data)) {
          setAvailableProducts(response.data.data);
        } else {
          setError('Unexpected data format received from the API.');
        }
      } catch (error) {
        setError('Failed to fetch products. Please try again later.');
      }
    };

    fetchAvailableProducts();
  }, []);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const addProductToCompare = (product) => {
    if (!products.find(p => p.TabletID === product.TabletID)) {
      setProducts([...products, product]);
    }
    closePopup();
  };

  const removeProductFromCompare = (TabletID) => {
    setProducts(products.filter(product => product.TabletID !== TabletID));
  };

  const areValuesSame = (featureKey) => {
    const firstValue = products[0][featureKey];
    return products.every(product => product[featureKey] === firstValue);
  };

  const getBestValue = (featureKey) => {
    if (['Price', 'Weight'].includes(featureKey)) {
      return Math.min(...products.map(product => parseFloat(product[featureKey])));
    } else if (['BatteryCapacity', 'RAM', 'InternalStorage', 'ScreenSize', 'Resolution', 'RefreshRate'].includes(featureKey)) {
      return Math.max(...products.map(product => parseFloat(product[featureKey])));
    } else {
      return products[0][featureKey];
    }
  };

  const highlightBestValue = (featureKey, value) => {
    const bestValue = getBestValue(featureKey);
    return value === bestValue ? <span className="text-green-500 font-bold">{value}</span> : value;
  };

  // Updated feature list for tablets
  const featureList = [
    { key: 'Price', label: 'Price' },
    { key: 'Announced', label: 'Announced' },
    { key: 'Status', label: 'Status' },
    { key: 'NetworkTechnology', label: 'Network Technology' },
    { key: '2GBands', label: '2G Bands' },
    { key: '3GBands', label: '3G Bands' },
    { key: '4GBands', label: '4G Bands' },
    { key: '5GBands', label: '5G Bands' },
    { key: 'NetworkSpeed', label: 'Network Speed' },
    { key: 'WLAN', label: 'Wi-Fi Version' },
    { key: 'Bluetooth', label: 'Bluetooth Version' },
    { key: 'Dimensions', label: 'Dimensions' },
    { key: 'Weight', label: 'Weight (g)' },
    { key: 'Build', label: 'Build' },
    { key: 'Sim', label: 'SIM' },
    { key: 'WaterResistance', label: 'Water Resistance' },
    { key: 'DisplayType', label: 'Display Type' },
    { key: 'DisplaySize', label: 'Screen Size' },
    { key: 'DisplayResolution', label: 'Resolution' },
    { key: 'DisplayFeatures', label: 'Display Features' },
    { key: 'Protection', label: 'Protection' },
    { key: 'OS', label: 'Operating System' },
    { key: 'Chipset', label: 'Chipset' },
    { key: 'CPU', label: 'Processor' },
    { key: 'GPU', label: 'GPU' },
    { key: 'MemoryCardSlot', label: 'Memory Card Slot' },
    { key: 'InternalMemory', label: 'Internal Storage' },
    { key: 'MainCamera', label: 'Main Camera' },
    { key: 'MainCameraVideo', label: 'Main Camera Video' },
    { key: 'MainCameraFeatures', label: 'Main Camera Features' },
    { key: 'SelfieCamera', label: 'Selfie Camera' },
    { key: 'SelfieCameraVideo', label: 'Selfie Camera Video' },
    { key: 'SelfieCameraFeatures', label: 'Selfie Camera Features' },
    { key: 'SoundLoudspeaker', label: 'Sound (Loudspeaker)' },
    { key: 'Sound3_5mmJack', label: '3.5mm Jack' },
    { key: 'Positioning', label: 'Positioning' },
    { key: 'NFC', label: 'NFC' },
    { key: 'InfraredPort', label: 'Infrared Port' },
    { key: 'Radio', label: 'Radio' },
    { key: 'USB', label: 'USB' },
    { key: 'Sensors', label: 'Sensors' },
    { key: 'BatteryType', label: 'Battery Type' },
    { key: 'BatteryCharging', label: 'Charging Speed' },
    { key: 'BatteryWirelessCharging', label: 'Wireless Charging' },
    { key: 'BatteryReverseCharging', label: 'Reverse Charging' },
    { key: 'Colors', label: 'Available Colors' },
    { key: 'Models', label: 'Models' },
    { key: 'SAR', label: 'SAR' },
    { key: 'BoxContents', label: 'Box Contents' },
  ];
  

  const filteredAvailableProducts = availableProducts.filter(product =>
    !products.find(p => p.TabletID === product.TabletID) &&
    product.ModelName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!products || products.length < 2) {
    return (
      <div className="compare-page-wrapper">
        <p>No products selected for comparison.</p>
      </div>
    );
  }

  return (
    <div className="compare-tablet-page-container min-h-screen p-10">
      <h1 className="text-4xl font-extrabold text-center mb-10">Tablet Comparison</h1>

      <div className="toggle-differences mb-5">
        <label>
          <input
            type="checkbox"
            checked={showOnlyDifferences}
            onChange={() => setShowOnlyDifferences(!showOnlyDifferences)}
          />
          Show only differences
        </label>
      </div>

      <div className="comparison-table-wrapper">
        <table className="comparison-table w-full border-collapse border border-gray-200">
          <thead>
            <tr>
              <th>Feature</th>
              {products.map(product => (
                <th key={product.TabletID} className="p-4 border border-gray-200 text-center relative">
                  <button
                    onClick={() => removeProductFromCompare(product.TabletID)}
                    className="absolute top-2 right-2 text-red-500 text-lg"
                  >
                    &times;
                  </button>
                  <img
                    src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                    alt={product.ModelName}
                    className="w-24 mx-auto"
                    onError={(e) => { e.target.src = 'fallback_image.png'; }}
                  />
                  <p className="mt-2 font-bold">{product.ModelName}</p>
                  <p className="text-green-600 font-semibold">₹{product.Price}</p>
                </th>
              ))}
              <th className="p-4 border border-gray-200 text-center">
                <button
                  onClick={openPopup}
                  className="add-product-btn bg-green-500 text-white px-4 py-2 rounded"
                >
                  Add Product
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {featureList.map(({ key, label }) => {
              if (showOnlyDifferences && areValuesSame(key)) {
                return null;
              }

              return (
                <tr key={key} className="hover:bg-gray-100">
                  <td className="p-4 border border-gray-200 font-medium">{label}</td>
                  {products.map(product => (
                    <td key={product.TabletID} className="p-4 border border-gray-200 text-center">
                      {highlightBestValue(key, product[key] || 'N/A')}
                    </td>
                  ))}
                  <td className="p-4 border border-gray-200 text-center">-</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Popup for adding a product */}
      {isPopupOpen && (
        <div className="popup-wrapper fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="popup-content bg-white p-6 rounded-lg max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Add Product</h2>
              <button onClick={closePopup} className="text-red-500 text-xl">&times;</button>
            </div>

            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for a product..."
              className="w-full p-2 border rounded mb-4"
            />

            <div className="product-list max-h-60 overflow-y-auto">
              {filteredAvailableProducts.map(product => (
                <div
                  key={product.TabletID}
                  className="product-item p-3 border-b cursor-pointer hover:bg-gray-100"
                  onClick={() => addProductToCompare(product)}
                >
                  <div className="flex items-center">
                    <img
                      src={`https://admin.comparejunction.com/storage/images/${product.ImageURL}`}
                      alt={product.ModelName}
                      className="w-12 h-12 mr-4"
                    />
                    <p className="text-lg">{product.ModelName}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompareTabletPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './SmartphoneDetails.css'; // Link to the CSS file

const SmartphoneDetails = () => {
  const { id } = useParams();
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPhoneDetails = async () => {
      try {
        const response = await axios.get(`https://admin.comparejunction.com/api/smartphones/${id}`);
        setPhone(response.data.data); 
      } catch (err) {
        setError('Failed to fetch smartphone details');
      } finally {
        setLoading(false);
      }
    };

    fetchPhoneDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    phone ? (
      <div className="smartphone-details">
        <div className="phone-image-section">
          <img 
            src={`https://admin.comparejunction.com/storage/images/${phone.ImageURL}`} 
            alt={phone.ModelName} 
            className="phone-image"
          />
        </div>
        <div className="phone-specs-section">
          <h2>{phone.ModelName}</h2>
          <p><strong>Brand:</strong> {phone.brand.BrandName}</p>
          <p><strong>Category:</strong> {phone.category.CategoryName}</p>
          <p><strong>Price:</strong> {phone.Price} {phone.Currency}</p>
          <p><strong>Announced:</strong> {phone.Announced}</p>
          <p><strong>Status:</strong> {phone.Status}</p>
          <h3>Specifications</h3>
          <div className="specs-container">
            <div className="spec-item"><strong>Network Technology:</strong> {phone.NetworkTechnology}</div>
            <div className="spec-item"><strong>2G Bands:</strong> {phone.Band2G}</div>
            <div className="spec-item"><strong>3G Bands:</strong> {phone.Band3G}</div>
            <div className="spec-item"><strong>4G Bands:</strong> {phone.Band4G}</div>
            <div className="spec-item"><strong>5G Bands:</strong> {phone.Band5G}</div>
            <div className="spec-item"><strong>Network Speed:</strong> {phone.NetworkSpeed}</div>
            <div className="spec-item"><strong>WiFi Version:</strong> {phone.WiFiVersion}</div>
            <div className="spec-item"><strong>Bluetooth Version:</strong> {phone.BluetoothVersion}</div>
            <div className="spec-item"><strong>NFC:</strong> {phone.NFC ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>WLAN:</strong> {phone.WLAN ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Infrared Port:</strong> {phone.InfraredPort ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Radio:</strong> {phone.Radio ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>USB Type:</strong> {phone.USBType}</div>
            <div className="spec-item"><strong>Dimensions:</strong> {phone.Dimensions}</div>
            <div className="spec-item"><strong>Weight:</strong> {phone.Weight} g</div>
            <div className="spec-item"><strong>Build:</strong> {phone.Build}</div>
            <div className="spec-item"><strong>IP Rating:</strong> {phone.IPRating}</div>
            <div className="spec-item"><strong>Water Resistance:</strong> {phone.WaterResistance ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Display Type:</strong> {phone.DisplayType}</div>
            <div className="spec-item"><strong>Screen Size:</strong> {phone.ScreenSize} inches</div>
            <div className="spec-item"><strong>Resolution:</strong> {phone.Resolution}</div>
            <div className="spec-item"><strong>Refresh Rate:</strong> {phone.RefreshRate} Hz</div>
            <div className="spec-item"><strong>Display Features:</strong> {phone.DisplayFeatures}</div>
            <div className="spec-item"><strong>Protection:</strong> {phone.Protection}</div>
            <div className="spec-item"><strong>Processor:</strong> {phone.Processor}</div>
            <div className="spec-item"><strong>GPU:</strong> {phone.GPU}</div>
            <div className="spec-item"><strong>Chipset:</strong> {phone.Chipset}</div>
            <div className="spec-item"><strong>Operating System:</strong> {phone.OperatingSystem} (UI: {phone.CustomUI})</div>
            <div className="spec-item"><strong>RAM:</strong> {phone.RAM} GB</div>
            <div className="spec-item"><strong>Internal Storage:</strong> {phone.InternalStorage} GB</div>
            <div className="spec-item"><strong>Expandable Storage:</strong> {phone.ExpandableStorage ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Memory Card Slot:</strong> {phone.MemoryCardSlot}</div>
            <div className="spec-item"><strong>Internal Memory:</strong> {phone.InternalMemory}</div>
            <div className="spec-item"><strong>Battery Capacity:</strong> {phone.BatteryCapacity} mAh</div>
            <div className="spec-item"><strong>Charging Speed:</strong> {phone.ChargingSpeed} W</div>
            <div className="spec-item"><strong>Wireless Charging:</strong> {phone.WirelessCharging ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Battery Type:</strong> {phone.BatteryType}</div>
            <div className="spec-item"><strong>Battery Charging:</strong> {phone.BatteryCharging}</div>
            <div className="spec-item"><strong>Battery Wireless Charging:</strong> {phone.BatteryWirelessCharging}</div>
            <div className="spec-item"><strong>Battery Reverse Charging:</strong> {phone.BatteryReverseCharging ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Sound Loudspeaker:</strong> {phone.SoundLoudspeaker ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Sound 3.5mm Jack:</strong> {phone.Sound3_5mmJack ? 'Yes' : 'No'}</div>
            <div className="spec-item"><strong>Positioning:</strong> {phone.Positioning}</div>
            <div className="spec-item"><strong>Sensors:</strong> {phone.Sensors}</div>
            <div className="spec-item"><strong>Special Features:</strong> {phone.SpecialFeatures}</div>
            <div className="spec-item"><strong>Colors:</strong> {phone.Colors}</div>
            <div className="spec-item"><strong>Models:</strong> {phone.Models}</div>
            <div className="spec-item"><strong>SAR:</strong> {phone.SAR}</div>
            <div className="spec-item"><strong>Box Contents:</strong> {phone.BoxContents}</div>
          </div>
        </div>
      </div>
    ) : (
      <div>No details available for this smartphone.</div>
    )
  );
};

export default SmartphoneDetails;
